import React from 'react';

const Footer = () => (
  <footer className="bg-gray-800 text-white py-8">
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap justify-between">
        {/* Logo and Description */}
        <div className="w-full md:w-1/3 mb-6 md:mb-0">
          <h2 className="text-xl font-bold mb-2">HollowByte</h2>
          <p>HollowByte is a "looks interesting, let's do it" company.</p>
        </div>

        {/* Contact */}
        <div className="w-full md:w-1/3">
          <h3 className="text-lg font-semibold mb-2">Contact Us</h3>
          <p>609 Miyamasuzaka building<br />Tokyo, Shibuya ward, Shibuya 2-19-15<br />Japan</p>
          <p><strong>Email:</strong> contact@hollowbyte.com</p>
        </div>
      </div>
    </div>
    <div className="text-center mt-8">
      <p>&copy; 2024 HollowByte, LLC. All Rights Reserved</p>
    </div>
  </footer>
);

export default Footer;
