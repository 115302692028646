import React from "react";
const Header = () => (
  <header>
    <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
      <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
        <a href="https://hollowbyte.com" className="flex items-center">
          <picture>
            <source
              srcSet="assets/img/logo_black.png"
              media="(prefers-color-scheme: dark)"
            />
            <img
              src="assets/img/logo_white.png"
              className="mr-3 h-6 sm:h-9"
              alt="HollowByte Logo"
            />
          </picture>
          <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
            HollowByte
          </span>
        </a>
      </div>
    </nav>
  </header>
);

export default Header;
