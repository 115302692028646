import React from 'react';

const ProductsSection = () => (
  <section id="products" className="bg-gray-100 py-12">
    <div className="container mx-auto px-4">
      <header className="text-center mb-8">
        <h2 className="text-3xl font-bold">Our Products</h2>
        <p className="text-gray-600">Explore our range of products</p>
      </header>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <a href="https://alchana.com" target="_blank" rel="noopener noreferrer" className="bg-white shadow-lg rounded-lg overflow-hidden">
          <img src="assets/img/product-1.png" alt="Alchana" className="w-full h-48 object-cover" />
          <div className="p-6">
            <h3 className="text-xl font-semibold mb-2">Alchana</h3>
            <p className="text-gray-600">Sophisticated OSINT services.</p>
          </div>
        </a>
      </div>
    </div>
  </section>
);

export default ProductsSection;
