import React from 'react';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';

import ProductsSection from './components/ProductsSection';
import Footer from './components/Footer';

const App = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <main id="main">
        <ProductsSection />
      </main>
      <Footer />
    </div>
  );
}

export default App;